exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-ru-js": () => import("./../../../src/pages/404.ru.js" /* webpackChunkName: "component---src-pages-404-ru-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-ru-js": () => import("./../../../src/pages/contact.ru.js" /* webpackChunkName: "component---src-pages-contact-ru-js" */),
  "component---src-pages-experience-en-js": () => import("./../../../src/pages/experience.en.js" /* webpackChunkName: "component---src-pages-experience-en-js" */),
  "component---src-pages-experience-ru-js": () => import("./../../../src/pages/experience.ru.js" /* webpackChunkName: "component---src-pages-experience-ru-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-skills-en-js": () => import("./../../../src/pages/skills.en.js" /* webpackChunkName: "component---src-pages-skills-en-js" */),
  "component---src-pages-skills-ru-js": () => import("./../../../src/pages/skills.ru.js" /* webpackChunkName: "component---src-pages-skills-ru-js" */),
  "component---src-pages-works-en-js": () => import("./../../../src/pages/works.en.js" /* webpackChunkName: "component---src-pages-works-en-js" */),
  "component---src-pages-works-ru-js": () => import("./../../../src/pages/works.ru.js" /* webpackChunkName: "component---src-pages-works-ru-js" */)
}

